









































import { Component, Ref } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { cloneDeep } from "lodash";
import { NavigationGuardNext, Route } from "vue-router";

import ScenarioForm from "@/application-manager/components/ScenarioForm.vue";
import ScenarioRequestModel from "@/application-manager/models/ScenarioRequestModel";
import UnsavedChangesMixin from "@/shared/mixins/UnsavedChangesMixin";
import { AppSection } from "@/shared/models";

@Component({
  components: {
    ScenarioForm,
  },
})
export default class NewRevenueEventView extends mixins(UnsavedChangesMixin) {
  @Ref("infraCommandsSideBar") infraCommandsSideBar!: any;

  localScenario = new ScenarioRequestModel();
  isValidForm = true;

  readonly AppSection = AppSection;

  get instanceForWatchingUnsavedChanges() {
    // in order to receive the old and new value in the watch during deep viewing
    return cloneDeep(this.localScenario);
  }

  get isSavingInProgress(): boolean {
    return this.$store.state.scenarioStore.isSavingInProgress;
  }

  created() {
    document.title = this.$lang(
      "documentTitle",
      this.$lang("applicationManager.scenario.newScenarioTitle")
    );
  }

  async mounted() {
    this.handleWatchingUnsavedChanges();
    await this.$store.dispatch("loadInfraCommands");
  }

  destroyed() {
    this.$store.commit("clearErrorValidationMessage");
  }

  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    if (
      this.hasUnsavedChanges &&
      this.currentRoutePath === from.path &&
      !this.isSavedForm
    ) {
      this.showUnsavedChangesDialog(to);
    } else {
      next();
    }
  }

  handleValidateForm(value: boolean) {
    this.isValidForm = value;
  }

  async handleSave() {
    await this.$store
      .dispatch("createScenario", this.localScenario)
      .then((response) => {
        this.isSavedForm = true;
        this.$router.push({
          name: "scenario_view",
          params: {
            scenarioId: String(response.id),
          },
        });
      });
  }
}
